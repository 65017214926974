@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@100;300;400;600;700&display=swap');


$primary: #1C58A6; //#E6B400;
$secondary: #1C58A6;

@import "../../node_modules/bootstrap/scss/bootstrap.scss";

html, body {
    font-family: 'Rubik', sans-serif;
    color: #222F5C;
    padding: 0px;
    margin: 0px;
}

*:focus {
    outline: none !important;
    box-shadow: none !important;
}

.footer {
    font-size: 0.85rem;
    opacity: 0.75;
}

.bg-reallydark {
    background-color: #222F5C;
}

.accordion-item {
    border: 0px;
    
    .accordion-header {
        button {
            word-break: break-all !important;
            padding: 10px 14px;

            &:after {
                width: 0.75rem !important;
                height: 0.75rem !important;
                background-size: 0.75rem !important;
            }

        }
    }

}



.nowrap {
    * { white-space: nowrap; }
}

.ag-body-horizontal-scroll {
    display: none !important;
}

.ag-root-wrapper {
    border: 0px !important;
    min-height: 250px;
}

.ag-paging-panel {
    border-top: 0px !important;
    justify-content: flex-start !important;
}

.btn-outline-primary:hover, .btn-outline-primary:active, .btn-outline-secondary:hover, .btn-outline-secondary:active {
    color: #fff !important;
}

.navbar-nav {

    .nav-link {
        color: #666;
        padding: 15px 25px !important;
        border-bottom: #ffffff solid 2px !important;
        border-radius: 0px !important;

        &.active {
            color: $secondary !important;

            &:not(.right) {
                border-bottom: $secondary solid 2px !important;
            }

            &.right {
                border-right: $secondary solid 2px !important;
                
            }

        }

        &:hover {
            color: lighten($secondary, 10%) !important;

            &:not(.right) {
                border-bottom: lighten($secondary, 10%) solid 2px !important;
            }

            &.right {
                border-right: lighten($secondary, 10%) solid 2px !important;

            }

        }

    }

}


.nav-link {
    cursor: pointer;
}

.nav-tabs {

    .nav-item {
        

        .nav-link {
            border-radius: 0px !important;
            color: #000 !important;
            cursor: pointer;

            i {
                opacity: 0.5;
                cursor: pointer;

                &:hover {
                    opacity: 1;
                }

            }

            &:not(.active){
                border-right: solid #dbdbdb 1px !important;
                color: #666 !important;
            }

        }

    }

}

.list-group-item {
    border: 0px !important;
    padding: 0.25rem 0rem !important;
}

#home_featured {

    i {
        font-size: 4rem;
    }

}

*:disabled {
    opacity: 0.5 !important;
    cursor: not-allowed !important;
}

.badge {
    text-decoration: none;
}

.dropdown-item:active {
    color: #fff !important;
}

.border-lessdark {
    border-color: #666;
}

.fw-semibold {
    font-weight: 600;
}

// input:valid, textarea:valid, select:valid {
//     border-color: #198754;
// }

// #212121
// #363636
// #FFC700
// #D4D4D4
// #FFFFFF